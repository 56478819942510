import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { rem } from 'polished';
import { useTranslation } from 'react-i18next';

// import useLangRedirect from '../hooks/useLangRedirect';

import SEO from '../components/SEO';
import Layout from '../components/Layout';
import ArticleItem from '../components/ArticleItem';
import Row from '../components/Row';
import Col from '../components/Col';
import Hero from '../components/Hero';
import Link from '../components/Link';
// import { getSrc } from 'gatsby-plugin-image';

import { breakpoint } from '../theme';

import { SiteInformation } from '../utils/fragments';

const Container = styled.div`
  max-width: ${(props) => props.theme.grid.maxWidth};
  margin: 0 auto;
`;

// const Section = styled(Row)`
//   margin-bottom: ${rem(40)};

//   ${breakpoint('large')} {
//     margin-bottom: ${rem(70)};
//   }
// `;

// const SectionContent = styled(Col)`
//   text-align: center;
//   width: 100%;
//   max-width: ${rem(600)};
//   margin: 0 auto ${rem(28)};

//   ${breakpoint('mediumlarge')} {
//     max-width: ${rem(820)};
//     font-size: ${rem(19)};
//   }
// `;

const Articles = styled(Row)`
  position: relative;
  padding-top: ${rem(20)};
  padding-bottom: ${rem(40)};

  ${breakpoint('large')} {
    padding-bottom: ${rem(70)};
  }
`;

const Article = styled(ArticleItem)`
  /* display: block;
  padding: ${(props) => props.theme.spacing.gutter}; */
  width: 100%;
  /* text-align: center; */
`;

const ArticleCol = styled(Col)`
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  margin-bottom: 2.5rem;

  ${breakpoint('mediumlarge')} {
    width: 50%;
  }

  /* ${breakpoint('large')} {
    width: 33.333%;
  } */
`;

function Archive({ data }) {
  const { articles, page } = data;
  const { t } = useTranslation();

  // useLangRedirect(page);

  const featuredArticle = articles?.edges[0]?.node;

  const {
    // featuredImage,
    seo,
  } = page;

  // const featuredMedia = featuredImage?.node;
  // const itemImageSrc = getSrc(featuredMedia?.localFile);
  if (!page || !featuredArticle) {
    return null;
  }

  return (
    <Layout>
      <SEO
        title={seo.title}
        description={seo.metaDesc || seo.opengraphDescription}
        // image={itemImageSrc}
        page={page}
      />
      <Hero
        heading={featuredArticle.title}
        image={featuredArticle.featuredImage?.node?.localFile}
        // pageTitle
        preHeading={featuredArticle.categories.nodes.map((cat, index) => {
          return (
            <span key={cat.id}>
              {index > 0 && ', '}
              {cat.name}
            </span>
          );
        })}
        tint
        big
        actions={<Link to={featuredArticle.uri}>{t('readMore')}</Link>}
      >
        <div
          dangerouslySetInnerHTML={{ __html: featuredArticle.excerpt }} // eslint-disable-line react/no-danger
        />
      </Hero>

      <Container>
        <Articles>
          {articles.edges.map(({ node: article }) => (
            <ArticleCol key={article.id}>
              <Article data={article} />
            </ArticleCol>
          ))}
        </Articles>
      </Container>
    </Layout>
  );
}

Archive.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

Archive.fragments = [SiteInformation];

export const query = graphql`
  query ($id: String!) {
    page: wpCategory(id: { eq: $id }) {
      id
      uri
      seo {
        breadcrumbs {
          text
          url
        }
        canonical
        cornerstone
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        opengraphModifiedTime
        opengraphPublishedTime
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        schema {
          raw
        }
        title
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
      }
    }
    articles: allWpPost(
      sort: { fields: date, order: DESC }
      filter: { categories: { nodes: { elemMatch: { id: { eq: $id } } } } }
    ) {
      edges {
        node {
          id
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 850
                    height: 560
                    quality: 75
                    layout: CONSTRAINED
                  )
                }
              }
              sourceUrl
              caption
              guid
              id
              description
            }
          }
          content
          title
          slug
          uri
          seo {
            title
            canonical
            cornerstone
            focuskw
            metaDesc
            metaKeywords
            metaRobotsNofollow
            metaRobotsNoindex
            opengraphAuthor
            opengraphDescription
            opengraphImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 75, layout: FULL_WIDTH)
                }
              }
            }
            opengraphModifiedTime
            opengraphPublishedTime
            opengraphPublisher
            opengraphSiteName
            opengraphTitle
            opengraphType
            opengraphUrl
            twitterDescription
            twitterImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 75, layout: FULL_WIDTH)
                }
              }
            }
            twitterTitle
          }
          author {
            node {
              firstName
              lastName
              avatar {
                url
              }
            }
          }
          categories {
            nodes {
              id
              name
            }
          }
          date
          excerpt
          modified
          tags {
            nodes {
              name
            }
          }
        }
      }
    }
    site {
      ...SiteInformation
    }
  }
`;

export default Archive;
