import React from 'react';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { getImage, getSrc } from 'gatsby-plugin-image';

import Image from '../Image';
import Link from '../Link';

import { breakpoint } from '../../theme';

const Thumbnail = styled.div`
  display: block;
  width: 100%;
  overflow: hidden;
  margin-bottom: ${rem(12)};
`;

const Inner = styled(Link)`
  display: block;
  width: 100%;
  /* border-bottom: 1px solid ${(props) => props.theme.global.border.color}; */
  text-align: left;
`;

const ThumbnailPlaceholder = styled.div`
  display: block;
  width: 100%;
  width: ${rem(150)};
  padding-bottom: 56.25%;
  background-color: ${(props) => props.theme.palette.colors.grey};
`;

const Content = styled.div`
  width: 100%;
  flex-grow: 1;
`;

const ContentInner = styled.div`
  position: relative;
  display: block;
  width: 100%;
  color: ${(props) => props.theme.palette.text.primary};
  font-size: ${rem(18)};
  flex-grow: 1;

  p {
    line-height: 1.55;
  }
`;

const Title = styled.h3`
  display: block;
  width: 100%;
  margin-bottom: 0.7rem;
  font-size: ${rem(18)};

  ${breakpoint('large')} {
    font-size: ${rem(21)};
  }
`;

const Tags = styled.h4`
  display: inline-block;
  flex-grow: 1;
  margin: 0;
  font-size: 1em;
  color: ${(props) => props.theme.palette.colors.primary};
  text-transform: uppercase;
  letter-spacing: 0.07em;
  font-family: ${(props) => props.theme.typography.fonts.button};
  font-weight: ${(props) => props.theme.typography.weights.button.normal};
`;

const PostMeta = styled.div`
  display: flex;
  width: 100%;
  font-size: ${rem(14)};
  color: ${(props) => props.theme.palette.colors.fadedText};
  font-weight: ${(props) => props.theme.typography.weights.primary.normal};
  margin-bottom: ${rem(11)};

  ${breakpoint('large')} {
    font-size: ${rem(15)};
  }
`;

const PostDate = styled.div`
  width: 100%;
  font-size: ${rem(14)};
  color: ${(props) => props.theme.palette.colors.fadedText};
  font-weight: ${(props) => props.theme.typography.weights.primary.normal};

  ${breakpoint('large')} {
    font-size: ${rem(16)};
  }
`;

const Published = styled.time`
  display: inline-block;
`;

const ReadMore = styled.div`
  /* display: inline-block; */
  display: none;
  cursor: pointer;
  color: ${(props) => props.theme.palette.colors.primary};
  padding: 0.7em 0;
  font-size: ${(props) => props.theme.typography.button.buttonSizes.default};
  font-family: ${(props) => props.theme.typography.fonts.button};
  font-weight: ${(props) => props.theme.typography.weights.button.normal};
  text-transform: ${(props) => props.theme.typography.button.textTransform};
  letter-spacing: ${(props) => props.theme.typography.button.letterSpacing};
  transition: ${(props) =>
    `all ${props.theme.global.transition.duration} ${props.theme.global.transition.timing}`};
`;

const Root = styled.div`
  width: 100%;

  & :hover {
    ${Thumbnail} {
      opacity: 0.8;
    }
  }
`;

function ArticleItem({ data }) {
  const { t } = useTranslation();
  const {
    featuredImage,
    uri,
    title,
    excerpt,
    categories,

    date,
    // excerptLength,
  } = data;
  const withImage = true;
  const withExcerpt = false;

  const featuredMedia = featuredImage?.node;
  const itemImage = getImage(featuredMedia?.localFile);
  const src = getSrc(featuredMedia?.localFile);

  // const trimmedExcerpt =
  //   excerpt.length > excerptLength
  //     ? `${excerpt.substring(0, excerptLength - 3)}...`
  //     : excerpt;

  return (
    <Root>
      <Inner to={uri}>
        {withImage && (
          <Thumbnail>
            {itemImage ? (
              <Image
                image={itemImage}
                fallback={{
                  url: src,
                  alt: featuredMedia.altText || '',
                }}
                alt={featuredMedia.altText || ''}
              />
            ) : (
              <ThumbnailPlaceholder />
            )}
          </Thumbnail>
        )}

        <Content>
          <ContentInner>
            <PostMeta>
              <Tags>
                {categories.nodes.map((cat, index) => {
                  return (
                    <span key={cat.id}>
                      {index > 0 && ', '}
                      {cat.name}
                    </span>
                  );
                })}
              </Tags>
            </PostMeta>

            <Title>{title}</Title>

            {withExcerpt && (
              <div
                dangerouslySetInnerHTML={{ __html: excerpt }} // eslint-disable-line react/no-danger
              />
            )}

            <ReadMore>{t('readMore')}</ReadMore>
          </ContentInner>
          <PostDate>
            <Published>
              {DateTime.fromISO(date).toFormat('dd LLL, yyyy')}
            </Published>
          </PostDate>
        </Content>
      </Inner>
    </Root>
  );
}

ArticleItem.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  // excerptLength: PropTypes.number,
};

// ArticleItem.defaultProps = {
//   excerptLength: 100,
// };

export default ArticleItem;
